import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import HeartDiseaseInfo from "../../components/services/HeartDiseaseInfo";

const HeartDisease = () => {
  return (
    <Layout>
      <PageHeader text="Heart Disease" />
      <ServicesHeader>
        Heart disease is a collective term for several conditions that cause
        heart problems, including heart attacks. At Sunstate Medical Associates
        in Lake Mary, Florida, the board-certified internists have considerable
        experience in diagnosing and treating heart disease. Find out how
        working with them can improve your quality of life by calling Sunstate
        Medical Associates today or use the convenient online form.
      </ServicesHeader>
      <HeartDiseaseInfo />
      <Conditions />
    </Layout>
  );
};

export default HeartDisease;
