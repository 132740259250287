import React from "react";
import RequestButton from "../RequestButton";

const HeartDiseaseInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Heart Disease Q & A
        </p>

        <p className="text-xl text-gray-800 mb-2">What is heart disease?</p>
        <p className="text-md text-gray-600 mb-2">
          Heart disease isn’t just a single condition. In fact, there are
          numerous types of heart disease that can interfere with the healthy
          function of your heart. These include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Coronary artery disease (CAD)</li>
          <li>Heart attack</li>
          <li>Heart arrhythmias</li>
          <li>Heart failure</li>
          <li>Heart valve disease</li>
          <li>Congenital heart disease</li>
          <li>Cardiomyopathy</li>
          <li>Pericardial disease</li>
          <li>Aorta disease</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          In the United States, heart disease is the leading cause of early
          death. However, most forms of heart disease are preventable or
          treatable.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the symptoms of heart disease?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Symptoms of heart disease vary depending on which condition you have.
          Some of the common symptoms of heart problems include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Chest pain</li>
          <li>Tight chest</li>
          <li>Pressure in your chest</li>
          <li>Shortness of breath</li>
          <li>Dizziness or lightheadedness</li>
          <li>Fatigue</li>
          <li>Swollen legs</li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          You might also experience changes in your heartbeat, for example,
          tachycardia (racing heart) or bradycardia (slow heartbeat).
        </p>
        <p className="text-md text-gray-600 mb-2">
          The Sunstate Medical Associates team can diagnose the cause of your
          symptoms and provide expert treatment for people who have any form of
          heart disease.
        </p>
        <p className="text-md text-gray-600 mb-12">
          They have EKG (electrocardiogram) facilities onsite to look at your
          heart’s electrical activity. They also carry out holter monitoring,
          which involves wearing a machine that records your heart over several
          days as you go about your life. Holter monitoring is useful if your
          symptoms aren’t present when you visit Sunstate Medical Associates.
        </p>

        <p className="text-xl text-gray-800 mb-2">What causes heart disease?</p>
        <p className="text-md text-gray-600 mb-2">
          Congenital heart diseases are conditions some people are born with. In
          most cases, however, heart disease is something that you’ll develop at
          a later stage in your life.
        </p>
        <p className="text-md text-gray-600 mb-2">
          One of the main reasons why you might develop heart disease is a
          condition called atherosclerosis. This is a problem that causes
          narrowing of your arteries. Cholesterol and other substances build up
          to form plaque, a sticky residue that lines the walls of your
          arteries.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Atherosclerosis is typically related to your lifestyle and habits, for
          example:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Being overweight</li>
          <li>Smoking</li>
          <li>Poor diet</li>
          <li>Lack of exercise</li>
          <li>Excessive intake of alcohol</li>
          <li>Drug abuse</li>
          <li>Chronic stress</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          You can also develop heart disease if you have a health problem like
          hypertension (high blood pressure) or diabetes. Certain infections,
          poisonous substances in your environment, and connective tissue
          diseases can sometimes cause heart disease as well.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How is heart disease treated?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Treating heart disease usually involves addressing the underlying
          causes and managing the symptoms. For instance, if your lifestyle is
          affecting your heart health, it’s essential to make changes like
          losing weight and quitting smoking.
        </p>
        <p className="text-md text-gray-600 mb-2">
          You might need to take medications as well, depending on the type of
          heart disease you have. If you need surgery for heart disease, the
          Sunstate Medical Associates team can refer you to the best cardiac
          surgeons.
        </p>
        <p className="text-md text-gray-600 mb-10">
          If you have any symptoms of heart disease, the sooner you get
          treatment, the better your outcomes are likely to be. Call Sunstate
          Medical Associates today or book an appointment online.
        </p>
        <RequestButton />
      </div>
    </div>
  );
};

export default HeartDiseaseInfo;
